.breadCrumbs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 750px;
  padding: 20px 24px;
  border-radius: 10px;
  background-color: #FFF;
}

.mainPageLink {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  color: #0F1511;
  cursor: pointer;
}

.holidayLink {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  color: #7A808F;
  cursor: default;
}

.holidayActiveLink {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  color: #0F1511;
  cursor: pointer;
}

.holidayPostcardLink {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  color: #7A808F;
  cursor: default;
}

.holidayPostcardLinkHidden {
  display: none;
}

@media (max-width: 950px) {
  .breadCrumbs {
    max-width: 100%;
    margin-bottom: 20px;
    padding: 20px 23px;
  }
}
