.navigationWrap {
  margin-top: 50px;
}

.navigationHeaderWrap {
  position: sticky;
  width: 95%;
  top: 80px;
  background: white;
  z-index: 50;
  padding-bottom: 1px;
}

.navigationHeader {
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: 700;
  line-height: normal;
}

.navigationSearch {
  width: 90%;
}

.navigationSearchIcon {
  background-color: #F2F2EA;
}

.navigationSearchField {
  margin-bottom: 20px;
}

@media (max-width: 950px) {
  .navigationHeaderWrap {
    width: 100%;
  }

  .navigationMobileWrapOpen {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 20px;
    background: #fff;
    translate: 0;
    transition: translate .5s ease-in-out;
    z-index: 30;
  }

  .navigationMobileWrapHidden {
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0 20px;
    background: #fff;
    translate: 1100px;
    transition: translate .5s ease-in-out;
    z-index: 30;
    animation: hideAnimation 1.1s forwards;
  }

  @keyframes hideAnimation {
    to {
      height: 0;
      overflow: hidden;
    }
  }

  .navigationSearch {
    width: 100%;
  }
}
