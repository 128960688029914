.headerWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.headerLogo {
  width: 40px;
  height: 40px;
  padding-right: 12px ;
}

.headerMenuBtn {
  display: none;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
}

@media (max-width: 950px) {
  .headerWrap {
    position: sticky;
    top: 0;
    padding: 20px 20px;
    background: #fff;
    z-index: 50;
  }

  .headerMenuBtn {
    display: block;
  }
}
