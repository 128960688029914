/* CustomScrollbar.css */
.custom-scrollbar {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.scroll-content {
  overflow-y: scroll;
  height: 100%;
  padding-right: 28px; /* компенсируем убранный отступ для контента */
}

/* Стили для кастомного скроллбара */
.scroll-content::-webkit-scrollbar {
  width: 7px;
}

.scroll-content::-webkit-scrollbar-thumb {
  background-color: #F2F2EA;
  border-radius: 10px;
}

.scroll-content::-webkit-scrollbar-track {
  background-color: #F2F2EA40;
}

@media (max-width: 1430px) {
  .scroll-content {
    padding-right: 18px; /* компенсируем убранный отступ для контента */
  }
}

@media (max-width: 1000px) {
  .scroll-content {
    padding-right: 0; /* компенсируем убранный отступ для контента */
  }
}

@media (max-width: 950px) {
  .scroll-content::-webkit-scrollbar {
    width: 0;
  }

  .scroll-content::-webkit-scrollbar-thumb {
    border: none;
  }

  .scroll-content::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
