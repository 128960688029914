.postcardWrap {
  max-width: 830px;
  width: 100%;
  min-height: 1375px;
  margin-left: 5px;
  padding: 50px 40px 0;
  background-color: #F2F2EA;
  transition: height 1s ease-in-out;
}

.postcardImage {
  position: relative;
  left: -8px;
  width: 600px;
  height: 600px;
}

.holidayPostCardSwiper {
  position: relative;
  margin-bottom: 20px;
}

.holidayPostCardDate {
  display: inline-block;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: #6D6D5E;
}

.holidayPostCardRecommendBlock {
  display: flex;
  justify-content: space-between;
}

.holidayPostCardRecommend {
  margin-bottom: 30px;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.holidayPostCardRecommendImg {
  width: 225px;
  height: 225px;
  margin-right: 38px;
  margin-bottom: 10px;
  border-radius: 100%;
  overflow: hidden;
}

.holidayPostCardDescr {
  display: block;
  width: 100%;
  max-width: 225px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.nextSlideBtn {
  position: absolute;
  top: 270px;
  right: 1px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  color: black;
  background-color: #ffd15c;
  background-image: url("../../assets/icons/right-arrow.svg");
  z-index: 20;
}

.prevSlideBtn {
  position: absolute;
  top: 270px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  color: black;
  background-color: #ffd15c;
  background-image: url("../../assets/icons/left-arrow.svg");
  z-index: 20;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}

.swiper-slide:first-of-type {
  margin-left: 10px;
}

@media (max-width: 1430px) {
  .postcardWrap {
    max-width: 750px;
    padding: 50px 40px 0;
  }

  .postcardImage {
    width: 550px;
    height: 550px;
  }

  .nextSlideBtn {
    top: 270px;
    right: -5px;
    width: 60px;
    height: 60px;
  }

  .prevSlideBtn {
    top: 270px;
    left: -5px;
    width: 60px;
    height: 60px;
  }

  .holidayPostCardRecommendImg {
    width: 200px;
    height: 200px;
    margin-right: 40px;
  }
}

@media (max-width: 1250px) {
  .postcardWrap {
    max-width: 650px;
    min-height: 1340px;
  }

  .postcardImage {
    width: 500px;
    height: 500px;
  }

  .nextSlideBtn {
    top: 235px;
    right: -5px;
    width: 60px;
    height: 60px;
  }

  .prevSlideBtn {
    top: 235px;
    left: -5px;
    width: 60px;
    height: 60px;
  }

  .nextSlideBtn {
    background-image: url("../../assets/icons/rightMobileArrow.svg");
  }

  .prevSlideBtn {
    background-image: url("../../assets/icons/leftMobileArrow.svg");
  }

  .holidayPostCardRecommendImg {
    width: 180px;
    height: 180px;
    margin-right: 20px;
  }
}

@media (max-width: 1100px) {
  .postcardWrap {
    max-width: 600px;
  }

  .postcardImage {
    width: 450px;
    height: 450px;
  }

  .nextSlideBtn {
    top: 205px;
    right: -5px;
    width: 60px;
    height: 60px;
  }

  .prevSlideBtn {
    top: 205px;
    left: -5px;
    width: 60px;
    height: 60px;
  }

  .holidayPostCardRecommendImg {
    width: 165px;
    height: 165px;
  }
}

@media (max-width: 1000px) {
  .postcardWrap {
    max-width: 590px;
  }

  .postcardImage {
    width: 450px;
    height: 450px;
  }

  .nextSlideBtn {
    top: 205px;
    right: 10px;
    width: 60px;
    height: 60px;
  }

  .prevSlideBtn {
    top: 205px;
    left: 10px;
    width: 60px;
    height: 60px;
  }

  .holidayPostCardRecommendImg {
    margin-right: 50px;
  }
}

@media (max-width: 950px) {
  .swiper-slide:first-of-type {
    margin-left: 0;
  }

  .holidayPostCardDate {
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .postcardWrap {
    max-width: 100%;
    padding: 20px 0;
    min-height: 100%;
  }

  .postcardImage {
    width: 345px;
    height: 345px;
    margin-left: 0;
  }

  .nextSlideBtn {
    top: 145px;
    right: 15px;
    width: 60px;
    height: 60px;
  }

  .prevSlideBtn {
    top: 145px;
    left: 0;
    width: 60px;
    height: 60px;
  }

  .holidayPostCardRecommend {
   padding: 0 20px;
  }

  .holidayPostCardRecommendBlock {
    padding: 0 20px;
  }

  .holidayPostCardRecommendImg {
    margin-right: 10px;
  }
}
