@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@500;600;700&display=swap');

@tailwind base;

@tailwind components;

@tailwind utilities;

@layer components {
  .fs-20px {
    font-size: 20px;
  }
}

img {
 max-width: max-content;
}

body {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  font-family: Commissioner, sans-serif!important;
  text-transform: none;
  color: #0F1511;
}

h1,
h2,
h3,
h4,
h5 {
  color: #0F1511;
}

p {
  color: #0F1511;
}

div,
span {
  color: #0F1511;
}

.app-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 80px;
}

@media (max-width: 1430px) {
  body {
    max-width: 1270px;
  }

  .app-container {
    padding: 0 65px 0 80px;
  }
}

@media (max-width: 1250px) {
  body {
    max-width: 1095px;
  }

  .app-container {
    padding: 0 40px;
  }
}

@media (max-width: 1000px) {
  body {
    max-width: 890px;
  }

  .app-container {
    padding: 0 15px;
  }
}

@media (max-width: 950px) {
  .app-container {
    padding: 0;
    overflow-x: hidden;
  }
}
