.holidays-list {
  border-bottom: 1px solid #E1E1E4;
}

.holiday-wrap {
  border-radius: 15px;
  background: #F2F2EA;
  transition: background ease-in-out .1s;
}

.holiday-wrap:hover {
  background: #7CB0FF;
  transition: background ease-in-out .1s;
}

.holiday-active-wrap {
  background: #FFD25C;
  transition: background ease-in-out .1s;
}

.holiday-active-wrap:hover {
  background: #FFD25C;
  transition: background ease-in-out .1s;
}

.holiday-image-block {
  border-radius: 14px;
}

.holiday-img {
  border-radius: 15px;
  background: #F2F2EA;
}

.holiday-month {
  font-family: Commissioner, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.holiday-item {
  width: 410px;
}

.holiday-date {
  display: inline-block;
  margin-top: 37px;
  margin-bottom: 10px;
  font-family: Commissioner, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.holiday-descr {
  font-family: Commissioner, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.holiday-navigation {
  width: 410px;
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

@media (max-width: 1430px) {
  .holiday-navigation {
    width: 350px;
  }

  .holiday-item {
    width: 350px;
  }
}

@media (max-width: 1050px) {
  .holiday-navigation {
    width: 310px;
  }

  .holiday-item {
    width: 310px;
  }
}

@media (max-width: 950px) {
  .holiday-navigation {
    width: 100%;
    height: 100%;
  }

  .holiday-item {
    width: 100%;
  }
}
