.footerWrap {
  display: flex;
  justify-content: space-between;
  padding: 40px 0 30px;
}

.footerLogo {
  width: 40px;
  height: 40px;
  padding-right: 12px ;
}

.footerDescr {
  width: 25vw;
  margin-bottom: 24px;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 33px */
}

.footerGoogle {
  width: 160px;
  height: 40px;
  background: url("../../assets/icons/google-play-badge-ru.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: -18px;
}

.footerCharacter {
  display: block;
  width: 440px;
  height: 195px;
  margin-bottom: 30px;
  background: url("../../assets/icons/character.svg");
}

.footerLinkWrap {
  display: flex;
}

.footerLink {
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 80%; /* 19.6px */
  border-right: 1px solid #7A808F;
}

.footerLink:first-child {
  padding-left: 0;
}

.footerLink:last-child {
  padding-right: 0;
  border: none;
}

.footerLinksWrap {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1650px) {
  .footerDescr {
    width: 45vw;
  }
}

@media (max-width: 1250px) {
  .footerWrap {
    margin-right: 20px;
  }
}

@media (max-width: 1150px) {
  .footerDescr {
    width: 30vw;
  }
}

@media (max-width: 950px) {
  .footerWrap {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
  }

  .footerDescr {
    width: 90vw;
    font-size: 24px;
  }

  .footerGoogle {
    margin-bottom: 60px;
  }

  .footerCharacter {
    display: none;
  }

  .footerLinksWrap {
    align-items: center;
  }

  .footerLinkWrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footerLink {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    border-right: 1px solid #7A808F;
  }
}
