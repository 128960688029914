.holidaysListWrap {
  max-width: 830px;
  width: 100%;
  margin-left: 5px;
  padding: 50px 0 70px 40px;
  background-color: #F2F2EA;
  transition: height 1s ease-in-out;
}

.holidaysListHeader {
  margin-bottom: 10px;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-overflow: ellipsis;
}

.holidaysPopularBlock {
  position: relative;
  max-width: 830px;
  width: 100%;
  margin-bottom: 40px;
}

.holidaysPopularItemsBlock {
  min-height: 130px;
  position: relative;
  max-width: 830px;
  width: 100%;
}

.holidaysImageBlock {
  display: block;
  min-width: 120px;
  min-height: 120px;
  margin-right: 20px;
  margin-bottom: 60px;
  cursor: pointer;
}

.holidaysImage {
  width: 120px;
  height: 120px;
  margin-bottom: 12px;
  border-radius: 10px;
  overflow: hidden;
}

.holidaysImageDescr {
  display: inline-block;
  width: 120px;
  line-height: 22px
}

.holidayName {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 34px;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  color: black;
  transition: color ease-in-out .1s;
}

.holidayName:hover {
  color: #7CB0FF;
  transition: color ease-in-out .1s;
}

.holidayDate {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: #6D6D5E;
}

.holidayDescr {
  display: inline-block;
  margin-bottom: 10px;
  color: #000;
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.swiper-button-next {
  top: 38% !important;
  width: 35px!important;
  height: 35px!important;
  background-image: url("../../assets/icons/right-arrow.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  position: absolute;
  top: 38% !important;
  width: 35px!important;
  height: 35px!important;
  background-image: url("../../assets/icons/left-arrow.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev:after {
  display: none;
}

.popular-gradient-overlay {
  position: absolute;
  bottom: 57px;
  right: -2px;
  width: 30px;
  height: 121px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  z-index: 1;
}

.image-gradient-overlay {
  position: absolute;
  bottom: 60px;
  right: -2px;
  width: 30px;
  height: 120px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  z-index: 1;
}

@media (max-width: 1430px) {
  .holidaysPopularBlock {
    position: relative;
    max-width: 830px;
    width: 100%;
    margin-bottom: 40px;
  }

  .holidaysListWrap {
    max-width: 750px;
    width: 100%;
    margin-left: 3px;
    padding: 50px 0 70px 20px;
  }

  .holidaysImage {
    width: 110px;
    height: 110px;
    margin-bottom: 10px;
  }

  .holidaysImageBlock {
    min-width: 110px;
    min-height: 110px;
    margin-right: 15px;
    margin-bottom: 50px;
  }

  .popular-gradient-overlay {
    bottom: 55px;
    height: 110px;
  }

  .image-gradient-overlay {
    bottom: 50px;
    height: 110px;
  }
}

@media (max-width: 1250px) {
  .popular-gradient-overlay {
    bottom: 52px;
    height: 95px;
  }

  .holidaysListWrap {
    max-width: 600px;
    padding: 50px 0 70px 15px;
  }

  .holidaysImage {
    width: 95px;
    height: 95px;
    margin-bottom: 10px;
  }

  .holidaysImageBlock {
    min-width: 95px;
    min-height: 95px;
    margin-right: 15px;
    margin-bottom: 50px;
  }

  .holidaysImageDescr {
    width: 100px;
    line-height: 20px
  }

  .holidayDescr {
    font-size: 14px;
  }
}

@media (max-width: 1050px) {
  .holidaysListHeader {
    font-size: 30px;
  }

  .holidayName {
    font-size: 30px;
  }
}

@media (max-width: 1000px) {
  .holidaysListWrap {
    max-width: 500px;
    padding: 50px 0 70px 15px;
  }
}


@media (max-width: 950px) {
  .holidaysListWrap {
    min-width: 100%;
    padding: 50px 15px 70px 15px;
  }

  .popular-gradient-overlay {
    display: none;
  }

  .image-gradient-overlay {
    display: none;
  }
}
