.holidayCardWrap {
  max-width: 830px;
  width: 100%;
  margin-left: 5px;
  padding: 50px 40px 0;
  background-color: #F2F2EA;
  transition: height 1s ease-in-out;
}

.holidayCardHeader {
  margin-bottom: 10px;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.holidayCardDate {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: #6D6D5E;
}

.holidayCardDescr {
  display: inline-block;
  margin-bottom: 40px;
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.holidaysPostcardWrap {
  display: flex;
  flex-wrap: wrap;
}

.holidaysPostcardImageWrap {
  display: flex;
  flex-direction: column;
  margin-right: 37px;
}

.holidaysPostcardImageWrap:nth-child(3n) {
  margin-right: 0;
}

.holidaysPostcardImage {
  width: 225px;
  height: 225px;
  cursor: pointer;
}

.holidaysPostcardImage:nth-child(3n) {
  margin: 0;
}

.holidaysPostcardDescr {
  display: block;
  width: 225px;
  max-width: 225px;
  margin-bottom: 25px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 1451px) {

  .holidaysPostcardImage:nth-child(3n) {
    margin-right: 37px;
  }

  .holidaysPostcardImageWrap:nth-child(3n) {
    margin-right: 37px;
  }

  .holidaysPostcardWrap {
    justify-content: center;
  }
}

@media (max-width: 950px) {
  .holidayCardWrap {
    max-width: 100%;
    width: 100%;
    padding: 20px 0 20px;
  }

  .holidayCardHeader {
    padding: 0 20px;
  }

  .holidayCardDate {
    padding: 0 20px;
  }

  .holidayCardDescr {
    width: 97%;
    padding: 0 20px;
  }

  .holidaysPostcardImageWrap {
    max-width: 170px;
    max-height: 170px;
    min-width: 170px;
    min-height: 170px;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .holidaysPostcardImage {
    max-width: 170px;
    max-height: 170px;
    min-width: 170px;
    min-height: 170px;
    margin-right: 0;
  }

  .holidaysPostcardImage:nth-child(1n) {
    margin-right: 10px;
  }

  .holidaysPostcardImageWrap:nth-child(1n) {
    margin-right: 10px;
  }
}
